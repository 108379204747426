import React from 'react'
import styles from './stylesTransportGateway.module.scss';
import AllIcon from '../../images/transport-gateway/icon--all-blue.png';
import AllIconActive from '../../images/transport-gateway/icon--all-white.png';


export default function ButtonTransportGatewayItem ( { item, currItem, selectItem, index } ) {
  
  return (
    <button 
      className={ item.title === currItem ? styles.btn__active : styles.btn } 
      onClick={ () => selectItem(item.title) } 
    >
      { index < 10
        ? <div className={ styles.btnIcon }>{ '0' + index }</div>
        : <div className={ styles.btnIcon }><img src={ AllIcon } width='39' height='39' alt='all icon' /><img className={ styles.allActive } src={ AllIconActive } width='39' height='39' alt='all icon' /></div>
      }
      <span>{ item.title }</span>
    </button>
  );
}
