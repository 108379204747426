
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataTransportGateway';
import ViewTransportGatewayBkg from './ViewTransportGatewayBkg';
import ViewTransportGatewayOverlay from './ViewTransportGatewayOverlay';
import ButtonTransportGatewayItem from './ButtonTransportGatewayItem';
import styles from './stylesTransportGateway.module.scss';


export default function ViewTransportGateway () {

    const [ currItem, setCurrItem ] = useState(''); //to toggle between overlays and display selected
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem(newItem);
    }

    const overlaysQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "transport-gateway"}, base: {regex: "/frg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = overlaysQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.overlaySrc ); 
            item.overlayNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getOverlays = () => {
        const overlays = data.map( item => 
            <ViewTransportGatewayOverlay key={ item.title } item={ item } currItem={ currItem } />
        );
        return overlays;
    }

    const getButtons = () => {
        const buttons = data.map( (item, index) => 
            <li key={ item.title }>
                <ButtonTransportGatewayItem item={ item } currItem={ currItem } selectItem={ onItemSected } index={ index+1 } />
            </li>
        );
        return buttons;
    }

    return (
        <>
           <ViewTransportGatewayBkg />
           <div className={ styles.frg }>
             { getOverlays() }
           </div>
           <nav className={ styles.menu }>
                <h2 className={ styles.title }>Building a world class transport gateway</h2>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
        </>
    );

}