
export const data = [
    { 
        title: 'Future West Gate Tunnel',
        overlaySrc: 'frg--01.png',
        overlayNode: null
    },
    { 
        title: 'Future Western Interstate Freight Terminal (WIFT)',
        overlaySrc: 'frg--02.png',
        overlayNode: null 
    },
    { 
        title: 'Future Outer Metropolitan Ring Road (OMR)',
        overlaySrc: 'frg--03.png',
        overlayNode: null 
    },
    { 
        title: 'Future Fast Rail to Geelong',
        overlaySrc: 'frg--04.png',
        overlayNode: null 
    },
    { 
        title: 'Future Bay West Port',
        overlaySrc: 'frg--05.png',
        overlayNode: null 
    },
    { 
        title: 'Future Avalon Airport Rail Link',
        overlaySrc: 'frg--06.png',
        overlayNode: null 
    },
    { 
        title: 'Future Drysdale Bypass',
        overlaySrc: 'frg--07.png',
        overlayNode: null 
    },
    { 
        title: 'Future Geelong Ring Road extension',
        overlaySrc: 'frg--08.png',
        overlayNode: null 
    },
    { 
        title: 'Future Waurn Ponds Station Upgrade and Armstrong Creek / Torquay Transit Corridor',
        overlaySrc: 'frg--09.png',
        overlayNode: null 
    },
    { 
        title: 'All',
        overlaySrc: 'frg--all.png',
        overlayNode: null 
    }
];