import React from 'react';
import SEO from '../components/seo/SEO';
import ViewTransportGateway from '../components/transportGateway/ViewTransportGateway';


export default function TransportGateway () { 

  return (
    <>
      <SEO title='Transport Gateway' />
      <ViewTransportGateway />
    </>
  );

}
