import React, { useRef, useEffect } from 'react'
import Img from 'gatsby-image';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesTransportGateway.module.scss';


export default function ViewTransportGatewayOverlay ( { item, currItem } ) {

  	const containerRef = useRef(null);
	useEffect(() => {
        item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
    });

  	return (
    	<div className={ styles.overlay } ref={ containerRef }>
        	<Img fixed={ item.overlayNode.childImageSharp.fixed } key={ item.overlayNode.id } alt={ item.title } />
    	</div>
  	);

}
